<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useNetw from "@useNetw";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
import { required } from "vuelidate/lib/validators";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

// import { tableData } from "./dataAdvancedtable";

/**
 * Advanced-table component
 */
export default {
  page: {
    title: "เพิ่มข้อมูลสินค้า",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader, Multiselect, vueDropzone: vue2Dropzone }, // vueDropzone: vue2Dropzone
  data() {
    return {
      // tableData: tableData,

      title: "เพิ่มข้อมูลสินค้า",
      items: [
        {
          text: "ฝ่ายขาย",
          active: true,
        },
        {
          text: "ข้อมูลสินค้า",
          active: false,
          href: "/products",
        },
        {
          text: "เพิ่มข้อมูลสินค้า (หรือแก้ไข)",
          active: true,
        },
      ],
      rowModel: [],
      selected: "",
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      branchMaster: [],
      totalRecords: 0,
      isHidden: false,
      payment: "",
      type: "",
      roId: "",
      serviceRo: [],
      // selectMode: "single",
      userBranchId: "",
      branchId: "",
      //   payMethCode: "",
      loading: undefined,
      nameTh: "",
      nameEn: "",
      submitform: false,
      errormessage: "กรุณากรอกข้อมูลให้ครบ",
      userBranch: "",
      filterOn: [],
      rows: [],
      sortBy: "age",
      sortDesc: false,
      overlayFlag: false,
      filter: {
        modelCode: "",
        nameTh: "",
      },

      fieldsModel: [
        {
          key: "modelCode",
          sortable: true,
          label: "รหัสรุ่น",
        },
        {
          key: "nameTh",
          sortable: true,
          label: "ชื่อรุ่น",
        },
      ],

      selectMode: "single",

      totalPageModel: "",
      perPageModel: 10,
      currentPageModel: 1,
      totalRecordModel: "",
      pageOptionsModel: [5, 10, 25, 50, 100],

      visible: false,
      index: 0,

      fieldsSelect: [
        {
          key: "index",

          label: "ลำดับ",
        },
        {
          key: "branchNameTh",
          label: "ชื่อสาขาที่ใช้งานได้",
        },
        {
          key: "action",
          label: "นำออก",
        },
      ],
      fieldsMaster: [
        {
          key: "index",

          label: "ลำดับ",
        },
        {
          key: "nameTh",
          label: "ชื่อสาขา",
        },
        {
          key: "action",
          label: "เพิ่ม",
        },
      ],
      allImg: "",
      rowsBranchP: [],
      branchMasterSelect: [],
      editor: ClassicEditor,
      editorData: "",
      editorConfig: {
        // The configuration of the editor.
      },

      dropzoneOptions: {
        url: `${appConfig.api.baseURL}api/d-product/image/upload-cover`,
        thumbnailWidth: 150,
        maxFilesize: 10,
        // uploadMultiple: true,
        paramName: "image",
        init: function() {
          const user = JSON.parse(
            localStorage.getItem(appConfig.storageUserKey)
          );
          this.token = `${user[appConfig.storageTokenKeyName]}`;
        },
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem(appConfig.storageUserKey))[
              appConfig.storageTokenKeyName
            ]
          }`,
        },
      },
      dismissSecs: 3,
      dismissCountDown: 0,
      resalert: "",

      nameThProduct: "",
      nameEnProduct: "",
      shortNameTh: "",
      shortNameEn: "",
      prodTypeId: "",
      manageBranch: "",
      price: "",
      description: "",
      cc: "",
      modelYear: "",
      brandId: "",
      modelId: "",
      prodCode: "",
      rowsProductType: [],
      rowsBrand: [],
      rowsModel: [],
      rowsFullImg: [],
      coverImage: "",
    };
  },
  validations: {
    nameThProduct: {
      required,
    },
    brandId: {
      required,
    },
    modelId: {
      required,
    },
    price: {
      required,
    },
    prodTypeId: {
      required,
    },
  },

  computed: {
    /**
     * Total no. of records
     */
    // rows() {
    //   return this.rows.length;
    // },
  },
  mounted() {
    // const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
    // const arrayBranch = [];
    // user.userAccessBranch.forEach((item) => {
    //   arrayBranch.push(item.branchId);
    // });
    // this.branchMaster = user.userAccessBranch;
    // this.userBranchId = user.branchId;
    // this.userBranch = arrayBranch;
    // this.totalRows = this.rows.total;

    this.getProductType();
    this.getBrand();
    if (this.$route.params.prodIdBase64 != null) {
      this.getData();
      // this.getBranchProduct();
    }
    // this.getModel();
  },
  created() {
    // this.getlocalData();
  },
  methods: {
    /**
     * Search the table data with search input
     */
    customLabel({ modelCode, nameEn }) {
      return `[${modelCode != null ? modelCode : ""}] - ${
        nameEn != null ? nameEn : " "
      }`;
    },
    getlocalData() {
      const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
      const arrayBranch = [];
      user.userAccessBranch.forEach((item) => {
        arrayBranch.push(item.branchId);
      });
      this.branchMaster = user.userAccessBranch;
      this.userBranchId = user.branchId;
      this.userBranch = arrayBranch;
      this.totalRows = this.rows.total;
    },
    showImg() {
      this.index = 0;
      this.visible = true;
      this.fullImage();
    },
    handleHide() {
      this.visible = false;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      // this.currentPage = 1;
    },
    fullImage: function() {
      this.overlayFlag = true;
      useNetw
        .get("api/d-product/image/full-size", {
          params: {
            fileId: this.coverImage,
          },
        })
        .then((response) => {
          this.allImg = response.data.data.filePath;
          // this.allImg = response.data.data.coverImageThumbnail;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
          // this.isHidden = true;
        });
    },
    getData: function() {
      this.overlayFlag = true;
      useNetw
        .get("api/d-product/show", {
          params: {
            prodId: atob(this.$route.params.prodIdBase64),
          },
        })
        .then((response) => {
          this.rows = response.data;

          this.prodCode = response.data.data.prodCode;
          this.brandId = {
            brandId: response.data.data.brandId,
            nameEn: response.data.data.vehicleBrandNameEn,
          };
          this.modelId = {
            modelId: response.data.data.modelId,
            nameEn: response.data.data.vehicleModelNameEn,
          };
          // this.cc = { cc: response.data.data.cc };
          this.cc = response.data.data.cc;
          if (response.data.data.description != null) {
            this.editorData = response.data.data.description;
          }
          this.modelYear = response.data.data.modelYear;
          this.nameThProduct = response.data.data.nameTh;
          this.nameEnProduct = response.data.data.nameEn;
          this.shortNameTh = response.data.data.nameTh;
          this.price = response.data.data.price;
          this.prodTypeId = {
            prodTypeId: response.data.data.prodTypeId,
            nameTh: response.data.data.productTypeNameTh,
          };
          this.shortNameTh = response.data.data.shortNameTh;
          this.shortNameEn = response.data.data.shortNameEn;
          this.allImg = response.data.data.coverImageThumbnail;
          this.coverImage = response.data.data.coverImage;
          this.getBranchProduct();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
          // this.isHidden = true;
        });
    },
    getProductType: function() {
      this.overlayFlag = true;
      useNetw
        .get("api/d-product/product-type", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
          },
        })
        .then((response) => {
          this.rowsProductType = response.data.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
          // this.isHidden = true;
        });
    },
    getBrand: function() {
      useNetw
        .get("api/d-product/vehicle-brand", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
          },
        })
        .then((response) => {
          this.rowsBrand = response.data.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.isHidden = true;
        });
    },
    getModel: function() {
      useNetw
        .get("api/d-product/vehicle-model", {
          params: {
            page: this.currentPage,
            perPage: 100,
            brandId: this.brandId != null ? this.brandId.brandId : undefined,
            modelCode: this.filter.modelCode,
            nameTh: this.filter.nameTh,
          },
        })
        .then((response) => {
          this.rowsModel = response.data.data;
          this.modelId = [];
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.isHidden = true;
        });
    },
    tooltipForm() {
      this.submitform = true;
      this.$v.$touch();
      if (this.$v.$invalid != true) {
        this.submitProduct();
      }
    },
    submitProduct: function() {
      useNetw
        .post("api/d-product/store", {
          prodCode: this.prodCode != null ? this.prodCode : undefined,
          nameTh: this.nameThProduct,
          nameEn: this.nameEnProduct != null ? this.nameEnProduct : undefined,
          shortNameTh: this.shortNameTh != null ? this.shortNameTh : undefined,
          shortNameEn: this.shortNameEn != null ? this.shortNameEn : undefined,
          brandId: this.brandId.brandId,
          modelId: this.modelId.modelId,
          prodTypeId: this.prodTypeId.prodTypeId,
          manageBranch: this.manageBranch,
          price: this.price,
          description: this.editorData,
          // cc: this.cc,
          // modelYear: JSON.stringify(this.modelYear != null ? this.modelYear.year : undefined),
          modelYear: this.modelYear,
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.$router.push({
            name: "detail-product",
            params: { prodIdBase64: btoa(response.data.prodId) },
          });
          // this.getBranchProduct();
          // this.getData();
          // this.getlocalData();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {});
    },
    handleChangePage(page) {
      this.currentPageModel = page;
      this.getModel();
    },
    handlePageChange(active) {
      this.perPage = active;
      this.currentPageModel = 1;
      this.getModel();
    },
    handleSearch() {
      this.getModel();
    },
    updateProduct: function() {
      useNetw
        .put("api/d-product/update", {
          prodId: atob(this.$route.params.prodIdBase64),
          prodCode: this.prodCode != null ? this.prodCode : undefined,
          nameTh: this.nameThProduct,
          nameEn: this.nameEnProduct != null ? this.nameEnProduct : undefined,
          shortNameTh: this.shortNameTh != null ? this.shortNameTh : undefined,
          shortNameEn: this.shortNameEn != null ? this.shortNameEn : undefined,
          brandId: this.brandId.brandId,
          modelId: this.modelId.modelId,
          prodTypeId: this.prodTypeId.prodTypeId,
          manageBranch: this.manageBranch,
          price: this.price,
          description: this.editorData,
          // cc: this.cc,
          // modelYear: JSON.stringify(this.modelYear != null ? this.modelYear.year : undefined),
          modelYear: this.modelYear,
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.$router.push({ name: "products" });
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {});
    },
    sendingEvent(file, xhr, formData) {
      formData.append("prodId", atob(this.$route.params.prodIdBase64));
    },
    AddBranch(branch) {
      this.overlayFlag = true; //skeleton true
      useNetw
        .post("api/d-product/branch/store", {
          prodId: parseInt(atob(this.$route.params.prodIdBase64)),
          branchId: branch.branchId,
        })
        .then((response) => {
          this.showAlert(response.data.message);
          this.getBranchProduct();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.$refs.modalService.hide();
          this.overlayFlag = false; //skeleton true
        });
    },
    showAlert(response) {
      this.dismissCountDown = this.dismissSecs;
      this.resalert = response;
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    delBranch(branch) {
      this.overlayFlag = true; //skeleton true
      useNetw
        .delete("api/d-product/branch/delete", {
          data: {
            prodId: atob(this.$route.params.prodIdBase64),
            branchId: branch.branchId,
          },
        })
        .then((response) => {
          this.showAlert(response.data.message);
          this.getBranchProduct();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.$refs.modalService.hide();
          this.overlayFlag = false; //skeleton true
        });
    },
    selectModel(data) {
      // this.data = data[0];
      // var paymentId = {
      //   paymentCode: this.data.paymentCode,
      //   customerNameTh: this.data.customerNameTh,
      //   customerFamilyNameTh: this.data.customerFamilyNameTh,
      // };
      // this.form.paymentId = paymentId;
      this.modelId = {
        modelCode: data[0].modelCode,
        modelId: data[0].modelId,
        nameEn: data[0].nameEn,
      };
      this.$refs["modalModel"].hide();
      //log(data);
    },
    getBranchProduct() {
      this.overlayFlag = true;
      this.getlocalData();
      // this.branchMasterSelect = this.branchMaster;
      useNetw
        .get("api/d-product/branch", {
          params: {
            prodId: atob(this.$route.params.prodIdBase64),
          },
        })
        .then((response) => {
          // if (response.data.branch.length != 0) {
            this.rowsBranchP = response.data.branch;
            // this.getlocalData();
            this.branchMasterSelect = this.branchMaster;

            this.branchMaster.forEach((branch, indexBranch) => {
              this.rowsBranchP.forEach((item) => {
                if (branch.branchId == item.branchId) {
                  delete this.branchMasterSelect[indexBranch];
                }
              });
            });
          // }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    addAllBranch() {
      this.overlayFlag = true; //skeleton true
      useNetw
        .post("api/d-product/branch/store-all", {
          prodId: atob(this.$route.params.prodIdBase64),
        })
        .then((response) => {
          this.showAlert(response.data.message);
          this.getBranchProduct();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.$refs.modalService.hide();
          this.overlayFlag = false; //skeleton true
        });
    },
    delAllBranch() {
      this.overlayFlag = true; //skeleton true
      useNetw
        .delete("api/d-product/branch/delete-all", {
          data: {
            prodId: atob(this.$route.params.prodIdBase64),
          },
        })
        .then((response) => {
          this.showAlert(response.data.message);
          this.getBranchProduct();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.$refs.modalService.hide();
          this.overlayFlag = false; //skeleton true
        });
    },
  },
  middleware: "authentication",
};
</script>
<style>
@media only screen and (min-width: 500px) {
  .alert-fixed {
    position: fixed;
    top: 50px;
    left: 65%;
    width: 30%;
    z-index: 9999;
    border-radius: 0px;
  }
}
.input-placeholder {
  font-size: 12px;
}
.modal-custom .modal-dialog {
  max-width: 95%;
}
</style>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <b-alert
        class="alert-fixed fixed-top rounded-1 m-4 bg-light alert-front"
        :show="dismissCountDown"
        variant="success"
        dismissible
        @dismissed="dismissCountDown = 0"
        @dismiss-count-down="countDownChanged"
      >
        <p class="float-end">{{ dismissCountDown }} วินาที...</p>
        <p>แจ้งเตือน</p>
        <hr />
        <p>{{ resalert }}</p>
        <!-- <b-progress
        variant="warning"
        :max="dismissSecs"
        :value="dismissCountDown"
        height="4px"
      ></b-progress> -->
      </b-alert>
      <div class="col-12">
        <b-overlay
          :show="overlayFlag"
          :spinner-variant="this.$overlayVariant"
          :spinner-type="this.$overlayType"
          :rounded="this.$overlayRounded"
        >
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12">
                  <div class="row">
                    <div class="col-6 col-sm-4 col-md-3">
                      <div class="mb-3 position-relative">
                        <code> * </code>ประเภทสินค้า:
                        <multiselect
                          v-model="prodTypeId"
                          label="nameTh"
                          :options="rowsProductType"
                          :show-labels="false"
                          open-direction="bottom"
                          placeholder=""
                          :class="{
                            'is-invalid': submitform && $v.prodTypeId.$error,
                          }"
                        >
                          <span slot="noResult">ไม่พบข้อมูล</span>
                        </multiselect>
                        <div
                          v-if="submitform && $v.prodTypeId.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.prodTypeId.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-6 col-sm-4 col-md-3">
                      <div class="mb-3 position-relative">
                        รหัสสินค้า:
                        <b-form-input
                          v-model="prodCode"
                          class="form-control"
                        ></b-form-input>
                      </div>
                    </div>
                    <div class="col-6 col-sm-4 col-md-3">
                      <div class="mb-3 position-relative">
                        <code> * </code>ชื่อสินค้า(ไทย):
                        <b-form-input
                          v-model="nameThProduct"
                          class="form-control"
                          :class="{
                            'is-invalid': submitform && $v.nameThProduct.$error,
                          }"
                        ></b-form-input>
                        <div
                          v-if="submitform && $v.nameThProduct.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.nameThProduct.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-6 col-sm-4 col-md-3">
                      <div class="mb-3 position-relative">
                        ชื่อสินค้า(อังกฤษ):
                        <b-form-input
                          v-model="nameEnProduct"
                          class="form-control"
                        ></b-form-input>
                      </div>
                    </div>
                    <div class="col-6 col-sm-4 col-md-2">
                      <div class="mb-3 position-relative">
                        ชื่อย่อ(ไทย):
                        <b-form-input
                          v-model="shortNameTh"
                          class="form-control"
                        ></b-form-input>
                      </div>
                    </div>
                    <div class="col-6 col-sm-4 col-md-2">
                      <div class="mb-3 position-relative">
                        ชื่อย่อ(อังกฤษ):
                        <b-form-input
                          v-model="shortNameEn"
                          class="form-control"
                        ></b-form-input>
                      </div>
                    </div>
                    <div class="col-5 col-sm-4 col-md-3">
                      <div class="mb-3 position-relative">
                        <code> * </code>ยี่ห้อ:
                        <multiselect
                          v-model="brandId"
                          label="nameEn"
                          :options="rowsBrand"
                          :show-labels="false"
                          open-direction="bottom"
                          @input="getModel"
                          placeholder=""
                          :class="{
                            'is-invalid': submitform && $v.brandId.$error,
                          }"
                        >
                          <span slot="noResult">ไม่พบข้อมูล</span>
                        </multiselect>
                        <div
                          v-if="submitform && $v.brandId.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.brandId.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-10 col-sm-7 col-md-4">
                      <div class="mb-3 position-relative">
                        <code> * </code>รุ่น:
                        <multiselect
                          v-model="modelId"
                          label="nameEn"
                          :options="rowsModel"
                          :show-labels="false"
                          :custom-label="customLabel"
                          open-direction="bottom"
                          placeholder=""
                          :class="{
                            'is-invalid': submitform && $v.modelId.$error,
                          }"
                        >
                          <span slot="noResult">ไม่พบข้อมูล</span>
                        </multiselect>
                        <div
                          v-if="submitform && $v.modelId.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.modelId.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-1 col-sm-1 col-1">
                      <div class="mt-4 position-relative">
                        <b-button
                          class="btn"
                          variant="info"
                          v-b-modal.modalModel
                        >
                          <i class="uil-file-search-alt"></i
                        ></b-button>
                      </div>
                    </div>
                    <!-- <div class="col-5 col-sm-6 col-md-2">
                      <div class="mb-3 position-relative">
                        ปีรุ่น:
                        <b-form-input
                          v-model="modelYear"
                          class="form-control text-end"
                        ></b-form-input>
                      </div>
                    </div>
                    <div class="col-5 col-sm-6 col-md-2">
                      <div class="mb-3 position-relative">
                        CC:
                        <b-form-input
                          v-model="cc"
                          class="form-control text-end"
                        ></b-form-input>
                      </div>
                    </div> -->
                    <div class="col-5 col-sm-4 col-md-2">
                      <div class="mb-3 position-relative">
                        <code> * </code>ราคา:
                        <b-form-input
                          v-model="price"
                          class="form-control text-end"
                          :class="{
                            'is-invalid': submitform && $v.price.$error,
                          }"
                        ></b-form-input>
                        <div
                          v-if="submitform && $v.price.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.price.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-7 col-sm-6 col-md-3">
                      <div class="mb-3 position-relative">
                        <!-- <code> * </code>CC: -->
                        <b-form-checkbox
                          v-model="manageBranch"
                          value="1"
                          unchecked-value="0"
                          class="mb-3 mt-4"
                          checked
                          plain
                          >แก้ไขสาขาด้วยหรือไม่</b-form-checkbox
                        >
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <label> คำอธิบายสินค้า</label>
                        <ckeditor
                          :editor="editor"
                          v-model="editorData"
                          :config="editorConfig"
                        ></ckeditor>
                      </div>
                    </div>

                    <div class="row mt-4">
                      <div class="col-12 col-sm-12 col-md-12">
                        <b-button
                          v-if="!this.$route.params.prodIdBase64"
                          class="btn float-end m-1"
                          variant="success"
                          @click="tooltipForm"
                        >
                          บันทึก
                        </b-button>
                        <b-button
                          v-if="this.$route.params.prodIdBase64"
                          class="btn float-end m-1"
                          variant="primary"
                          @click="updateProduct"
                        >
                          บันทึกการแก้ไขข้อมูล
                        </b-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card" v-if="this.$route.params.prodIdBase64">
            <div class="card-body">
              <div class="row"><h5>อัพโหลดไฟล์ภาพสินค้า</h5></div>
              <hr />
              <vue-dropzone
                id="image"
                ref="image"
                class="mt-4"
                :use-custom-slot="true"
                :options="dropzoneOptions"
                v-on:vdropzone-success="getData"
                v-on:vdropzone-sending="sendingEvent"
              >
                <div class="dropzone-custom-content">
                  <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                  <h4>อัพโหลดไฟล์ภาพ</h4>
                </div>
              </vue-dropzone>
              <div class="popup-gallery mt-3">
                <div
                  v-for="(data, index) in rows"
                  :key="'img' + index"
                  class="float-start m-2"
                  @click="() => showImg()"
                >
                  <div class="card shadow" v-if="data.coverImageThumbnail">
                    <img
                      :src="data.coverImageThumbnail"
                      class="d-block"
                      width="180"
                      height="180"
                    />
                  </div>
                </div>
                <vue-easy-lightbox
                  :visible="visible"
                  :imgs="this.allImg"
                  :index="index"
                  @hide="handleHide"
                ></vue-easy-lightbox>
              </div>
            </div>
          </div>

          <div class="card" v-if="this.$route.params.prodIdBase64">
            <div class="card-body">
              <div class="row"><h5>สาขาที่สามารถใช้งานสินค้า</h5></div>
              <hr />

              <div class="row">
                <div class="col-6">
                  <label for="tb"><b>เลือกสาขาเพื่อใช้งาน</b></label>
                  <span
                    class="badge bg-success font-size-12 ms-2"
                    @click="addAllBranch()"
                  >
                    <i class="uil uil-plus"></i>เพิ่มทั้งหมด
                  </span>
                  <b-table
                    :items="branchMasterSelect"
                    :fields="fieldsMaster"
                    responsive="sm"
                    ref="selectableTable"
                    selectable
                    class="border"
                  >
                    <template #cell(index)="rowsBranchP">
                      {{ rowsBranchP.index + 1 }}
                    </template>
                    <template #cell(action)="rowsBranchP">
                      <a
                        href="javascript:void(0);"
                        class="px-2 text-success"
                        v-b-tooltip.hover
                        title="Add"
                        @click="AddBranch(rowsBranchP.item)"
                      >
                        <i class="uil uil-plus font-size-14"></i>
                      </a>
                    </template>
                  </b-table>
                </div>

                <!-- //! appv branch-->

                <div class="col-6">
                  <label for="tb"><b>สาขาที่ใช้งานได้</b></label>
                  <span
                    class="badge bg-danger font-size-12 ms-2"
                    @click="delAllBranch()"
                  >
                    <i class="uil uil-trash"></i> นำออกทั้งหมด
                  </span>
                  <b-table
                    :items="rowsBranchP"
                    :fields="fieldsSelect"
                    responsive="sm"
                    ref="selectableTable"
                    selectable
                    class="border"
                  >
                    <template #cell(index)="rowsBranchP">
                      {{ rowsBranchP.index + 1 }}
                    </template>
                    <template #cell(action)="rowsBranchP">
                      <a
                        href="javascript:void(0);"
                        class="px-2 text-danger"
                        v-b-tooltip.hover
                        title="Delete"
                        @click="delBranch(rowsBranchP.item)"
                      >
                        <i class="uil uil-trash font-size-14"></i>
                      </a>
                    </template>
                  </b-table>
                </div>
              </div>
            </div>
          </div>
        </b-overlay>
      </div>
    </div>

    <b-modal
      ref="modalModel"
      id="modalModel"
      title="รายการรุ่น"
      hide-footer
      size="xl"
      centered
    >
      <div class="card-body">
        <div class="row">
          <div class="col-sm-12 col-md-12 align-items-center">
            <div class="row align-items-center">
              <div class="col-sm-4 col-md-4">
                <div class="mb-3 position-relative">
                  <label class="d-inline align-items-center"> ยี่ห้อ:</label>
                  <multiselect
                    v-model="brandId"
                    :options="rowsBrand"
                    label="nameEn"
                    :show-labels="false"
                    @input="getModel"
                    placeholder=""
                  >
                  </multiselect>
                </div>
              </div>
              <div class="col-sm-4 col-md-4">
                <label class="d-inline align-items-center m-2 text-end">
                  รหัสรุ่น:
                  <b-form-input
                    v-model="filter.modelCode"
                    type="search"
                    placeholder="รหัสรุ่น"
                    class="form-control ms-2"
                  ></b-form-input>
                </label>
              </div>
              <div class="col-sm-4 col-md-4">
                <label class="d-inline align-items-center m-2 text-end">
                  ชื่อรุ่น:
                  <b-form-input
                    v-model="filter.nameTh"
                    type="search"
                    placeholder="รหัสรุ่น"
                    class="form-control ms-2"
                  ></b-form-input>
                </label>
              </div>

              <div
                class="col-2 col-sm-2 col-md-2 text-end"
                style="margin-left: auto; margin-right: 0"
              >
                <b-button
                  class="btn btn ms-2"
                  variant="info"
                  type="submit"
                  @click="handleSearch"
                >
                  <i class="uil-search"></i>
                  ค้นหา
                </b-button>
              </div>
            </div>
          </div>
        </div>
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-skeleton-table
              :rows="5"
              :columns="6"
              :table-props="{ bordered: false, striped: true }"
              animation="throb"
            ></b-skeleton-table>
          </template>
          <div class="row mt-2">
            <!-- Search -->
            <div class="col-sm-12 col-md-12">
              <div class="row align-items-center">
                <div class="col-sm-5 col-md-3">
                  <div id="tickets-table_length" class="dataTables_length">
                    <label class="d-inline-block align-items-center">
                      แสดงผล
                      <b-form-select
                        v-model="perPageModel"
                        size="sm"
                        :options="pageOptionsModel"
                        @input="handlePageChange"
                      ></b-form-select
                      >&nbsp; รายการ
                    </label>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6"></div>
              </div>
            </div>
            <!-- End search -->
          </div>
          <!-- Table -->

          <!--  v-if="this.veh.name === 'เจ้าของรถ'" -->
          <div class="table-responsive mb-0">
            <b-table
              :items="rowsModel"
              :fields="fieldsModel"
              responsive="true"
              :per-page="perPageModel"
              :current-page="1"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              ref="selectableTable"
              selectable
              :select-mode="selectMode"
              @row-selected="selectModel"
            >
              <template #cell(paymentDate)="rowModel">
                <changeDate :date="rowModel.item.paymentDate"></changeDate>
              </template>
              <template #cell(status)="rowModel">
                <span
                  class="badge bg-warning font-size-12 ms-2"
                  v-if="rowModel.item.status === 'DFT'"
                >
                  ร่าง
                </span>
                <span
                  class="badge bg-info font-size-12 ms-2"
                  v-if="rowModel.item.status === 'SUBMIT'"
                >
                  รออนุมัติ
                </span>
                <span
                  class="badge bg-success font-size-12 ms-2"
                  v-if="rowModel.item.status === 'APV'"
                >
                  ยืนยันแล้ว
                </span>
                <span
                  class="badge bg-danger font-size-12 ms-2"
                  v-if="rowModel.item.status === 'CAN'"
                >
                  ยกเลิกแล้ว
                </span>
              </template>
              <!-- End edit table -->
            </b-table>
          </div>

          <div class="row">
            <span
              v-if="this.totalRecordModel === 0"
              class="text-center font-size-16"
              >ไม่พบรายการ</span
            >
            <div class="col" v-if="this.totalRecordModel > 0">
              <div class="col">
                หน้า {{ currentPageModel }} จาก
                {{ this.totalPageModel }} ทั้งหมด {{ totalRecordModel }} รายการ
              </div>

              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPageModel"
                      :total-rows="totalRecordModel"
                      :per-page="perPageModel"
                      @change="handleChangePage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
            <br />
            <hr />
            <br />
          </div>
          <!-- </div> -->
        </b-skeleton-wrapper>
      </div>

      <div class="row">
        <div class="col-md">
          <button class="btn btn-success float-end">ตกลง</button>
        </div>
      </div>
    </b-modal>
  </Layout>
</template>
